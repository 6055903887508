
.tcdi.tabbed.posts.container {
  margin: 0;

  min-height: 320px;
  padding: 0px 20px;

  .ui.container.entry-content {
    //margin: 0px;
    //padding: 25px 15px;
  }

  .label {
    font-weight: 400;
    margin: 0 !important;
    font-size: 16px;
    padding: 0 !important;
  }

  // download title spacing when in tabs
  .tcdi.download {
    .download-header{
      align-items: center;
      .ui.container.entry-content{
        padding-bottom: $base-unit !important;
        padding-top: $base-unit !important;
      }
    }
  }

}

.ui.grid.tabbed.posts {
  background-color: transparent !important;

  .row {
    //max-height: 600px;
    //overflow-y: hidden;
    //overflow-x: hidden;
    border: 1.2px solid #B7B7B7;
    border-radius: 0 0 5px 5px;
    padding: 0;
    margin: 0;
    .sixteen.wide.column.content {
      overflow: hidden;
      padding: 0;
      .entry-content {
        padding: 25px 33px 0 33px;
      }
    }
  }

  > .column {
    margin: 0 0 -1px 0;
    padding: .2vw 0 0 3px;
    &:first-child{
      padding-left: 0;
    }

    button.nav {
      @include border-radii(4px, 4px, 0, 0);
      background-color: #5BAF95;
      border: 1.2px solid #B7B7B7;
      height: 45px;
      width: 100%;
      padding: 0;
      margin: 0px;
      color: $tab-text-color;
      text-align: left;
      border-bottom: none;
      transform: translateY(0.1px) !important;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;

      .label {
        align-self: center;
        color: #ffffff;
        background-color: transparent !important;
        font-weight: 700;
      }
    }

    .button.one.nav {
      width: auto !important;

    }
  }

  .column.has-icon {
    button.nav {
      display: flex;
      justify-content: center;
      .icon {
        border: 0px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: auto;
        float: left;
        height: 30px;
        margin: 0 3px 0 0;
        filter: invert(0) saturate(0) brightness(200%);
        opacity: 1 !important;
      }
    }
  }

  .column.active.has-icon{
    button.nav{
      .icon{
        filter: none;
        opacity: 1 !important;
      }
    }
  }

  button.nav:hover {
    background-color: #398570;
  }

  .active {
    button.nav {
      color: #ffffff;
      background-color: #ffffff !important;
      transform: translateY(0.1px) !important;
      position: relative;
      z-index: 9;
      .label{
        color: #5BAF95;
        text-align: center;
        span {
          border-bottom: 2px solid #5BAF95; /* Set the border properties as needed */
          border-radius: 0;
          padding-bottom: 2px; /* Adjust the padding to control the space between text and line */
        }
      }

    }
  }
}


.ui.menu.tabbed.posts {
  padding: 0px;
  margin: 0px;

  .item {
    margin: 0px;
    padding: 0px;
    color: $gray !important;
    border: 0px;
    font-size: 18px;

    .label {
      background-color: transparent !important;
      color: $gray;
      padding: 0px;

    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }

  .item.active {

    .label {
      background-color: transparent !important;
      color: $et-green;
      font-weight: bold;
      padding: 0;

    }

    color: $et-green !important;
    font-weight: 700;
    padding-left: $base-unit;

    &:after {
      color: $gray;
      font-weight: normal;
    }
  }

  a.item.active::before {
    content: '';
    background-image: url('/green_arrow_active.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 18px !important;
    height: 20px !important;
    background-color: transparent !important;
    display: block !important;
    position: relative !important;
    margin-left: -18px;
    margin-right: $base-unit - 2;

  }

  .item::after {
    content: '|';
    margin-left: 18px;
    margin-right: 18px
  }


}


.container.tcdi.tabbed.posts.editing {
  .button.nav {
    margin-top: 20px;
  }
}

.wp-block-tcdi-components-tabbed-posts{
  // Light tabs option
  .tcdi-component[data-theme='light']{
    .ui.menu.tabbed.posts{
      .item{
        .label{
          font-size: 18px;
        }
      }
    }
  }
}

.tabbed-no-padding {
  .row{
    //overflow-x: hidden;
    .sixteen.wide.column.content{
      margin: 0 !important;
      padding: 0 !important;
      .ui.fluid.container.entry-content{
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
}

.tcdi.tabbed.posts.container.scrollable {
  margin: 0 0 65px 0;
  .content-tab {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.tabbed-no-padding {
  .row{
    //overflow-x: hidden;
    .sixteen.wide.column.content{
      margin: 0 !important;
      padding: 0 !important;
      .ui.fluid.container.entry-content{
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
}