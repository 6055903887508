.agree-and-download.link {
  font-size: 16px;
  cursor: pointer;
}
.agree-and-download.link:hover {
  color: #4d275d !important;
}

.modal {
  .agreement-header {
    display: flex !important;
    align-items: center;
    font-family: "Roboto", sans-serif !important;
    font-size: 23px !important;
    background-color: $ke-blue !important;
    color: #FFFFFF !important;
    font-weight: 300 !important;
    position: relative;
    height: 75px;
  }
  .agreement-header span {
    display: inline-block;
    transform: translateY(1px);
  }
  .agreement-header svg {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: 0 12px 0 10px;
    stroke-width: 4px;
  }
  .agreement-body {
    background-color: #F6F6F6 !important;
    padding: 30px 80px 5px 80px !important;
    .wp-block-separator {
      color: #e5e5e5;
      width: 100%;
    }
  }
  .agreement-footer {
    background-color: #F6F6F6 !important;
    border: none !important;
    padding-top: 30px !important;
    padding-bottom: 45px !important;
  }
  .modal-cancel-button {
    height: 35px;
    width: 140px;
    transform: translateX(96px);
    padding-top: 11px;
    font-size: 14px !important;
    font-weight: 400;
    color: #FFFFFF !important;
    background-color: #C96A6A !important;
    transition: background-color 0.3s ease;
  }
  .modal-cancel-button:hover {
    background-color: #a65454 !important;
  }
  .modal-agree-button{
    height: 35px;
    width: 140px;
    transform: translateX(-659px);
    padding-top: 11px;
    font-size: 14px !important;
    font-weight: 400;
    color: #FFFFFF !important;
    background-color: $ke-blue !important;
    transition: background-color 0.3s ease;
  }
  .modal-agree-button:hover {
    background-color: #48829b !important;
  }

}

/* Styles for editor*/

.has-standard-14-font-size {
  font-size: 14px;
}

.has-standard-12-font-size {
  font-size: 12px;
}

.has-standard-14-font-size{
  font-size: 14px;
}

.has-standard-15-font-size{
  font-size: 15px;
}

.has-standard-16-font-size {
  font-size: 16px;
}

.has-standard-18-font-size {
  font-size: 18px;
}

.has-standard-24-font-size {
  font-size: 24px;
}

.has-standard-27-font-size{
  font-size: 27px;
}

.has-standard-30-font-size {
  font-size: 30px;
}

.has-standard-36-font-size {
  font-size: 36px;
}

.has-standard-64-font-size {
  font-size: 64px;
  line-height: 110%;
}


.has-condensed-text {
  font-stretch: condensed;
  line-height: 115% !important;
}


.has-weight-300 {
  font-weight: 300;
}

.has-weight-400 {
  font-weight: 400;
}

.has-weight-700 {
  font-weight: 700;
}


* {
  -webkit-print-color-adjust: exact;
}



.has-white-color {
  color: #FFF
}

.has-purple-color {
  color: #9468a6
}

.has-dark-purple-color {
  color: #714882
}

.has-light-orange-color {
  color: #f2c76a
}

.has-grey-color {
  color: #9f9f9f
}

.has-black-color {
  color: #000000;
}

.has-regular-grey-color {
  color: #535454
}


.has-background-grey-color {
  color: #ececec
}

.has-lilac-color {
  color: #c589b6
}

.has-light-blue-color {
  color: #61b0d5
}

.has-light-purple-color {
  color: #9e7fb8
}

.has-blue-color {
  color: #5c9abe
}

.has-border-color {
  color: #41a1e0
}


.has-purple-background-color {
  background-color: #9468a6
}

.has-dark-purple-background-color {
  background-color: #714882
}

.has-light-orange-background-color {
  background-color: #f2c76a
}

.has-grey-background-color {
  background-color: #9f9f9f
}

.has-regular-grey-background-color {
  background-color: #535454
}


.has-background-grey-background-color {
  background-color: #ececec
}

.has-lilac-background-color {
  background-color: #c589b6
}

.has-light-blue-background-color {
  background-color: #61b0d5
}

.has-light-purple-background-color {
  background-color: #9e7fb8
}

.has-blue-background-color {
  background-color: #5c9abe
}

.has-border-background-color {
  background-color: #41a1e0
}
