
* {
  -webkit-print-color-adjust: exact;
}



.has-white-color {
  color: #FFF
}

.has-purple-color {
  color: #9468a6
}

.has-dark-purple-color {
  color: #714882
}

.has-light-orange-color {
  color: #f2c76a
}

.has-grey-color {
  color: #9f9f9f
}

.has-black-color {
  color: #000000;
}

.has-regular-grey-color {
  color: #535454
}


.has-background-grey-color {
  color: #ececec
}

.has-lilac-color {
  color: #c589b6
}

.has-light-blue-color {
  color: #61b0d5
}

.has-light-purple-color {
  color: #9e7fb8
}

.has-blue-color {
  color: #5c9abe
}

.has-border-color {
  color: #41a1e0
}


.has-purple-background-color {
  background-color: #9468a6
}

.has-dark-purple-background-color {
  background-color: #714882
}

.has-light-orange-background-color {
  background-color: #f2c76a
}

.has-grey-background-color {
  background-color: #9f9f9f
}

.has-regular-grey-background-color {
  background-color: #535454
}


.has-background-grey-background-color {
  background-color: #ececec
}

.has-lilac-background-color {
  background-color: #c589b6
}

.has-light-blue-background-color {
  background-color: #61b0d5
}

.has-light-purple-background-color {
  background-color: #9e7fb8
}

.has-blue-background-color {
  background-color: #5c9abe
}

.has-border-background-color {
  background-color: #41a1e0
}
