@import "../vars";

.tcdi.dashboard.gallery{
  a{
    color: $ke-purple;
  }
  .ui.grid{
    margin-right: 0;
  }
  .ui.grid>.row>.column{
    padding-right: 0;
  }
  .ui.two.column.grid,
  .ui.three.column.grid{
    > .row{
      padding-bottom: 0.5em;
      padding-top: 0.5em;
    }
    .wp-block-columns{
      margin-bottom:0;
    }
    .wp-block-column{
      &:first-child{
        padding-bottom: 0 !important;
        padding-left: 0 !important;
        padding-top: 0 !important;
      }
    }
  }
}
.tcdi.dashboard.gallery.horizontal {
  .grid,.column,.row{
    padding: 0px;
    margin: 0px;
  }
}
.tcdi.dashboard.gallery.vertical {

  .item {
    .preview {
      border: 2px solid $light-gray;
      border-radius: 5px;
      height: 100%;
      padding: 0px;

      .wp-block-columns {
        bottom: $base-unit;
        position: absolute;
        z-index: 1;
      }

      .wp-block-cover {
        margin-bottom: $base-unit * 6;
      }

      .ui.container.entry-content,
      figure, img, .wp-block-image {
        width: 100% !important;
        margin-bottom: .4rem;
        padding: 0px;
      }

      .wp-block-image .aligncenter {
        display: block;
      }

      p {
        padding-bottom: .4rem;
        font-weight: 400;
      }

      .wp-block-columns {
        padding: 0px 1rem;
      }

      p {

      }
    }


  }
}
