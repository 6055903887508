/* Customize the label (the container) */
@import "../vars";
.container.measures.group {

  span{
    background: none;
    font-size: 16px;
    font-weight: 300;
    border: 0;
    margin:0;
    padding: 0;
    cursor: pointer;

  }
  .inputs.lists {
    display: inline;
    margin-left: 1em;

    input[type="radio"] {
      appearance: none;
      background-color: #fff;
      margin: 0.5em;
      font: inherit;
      color: currentColor;
      width: 1.15em;
      height: 1.15em;
      border-radius: 50%;
      transform: translateY(-0.035em);
      :focus {
        outline: max(2em, 0.15em) solid currentColor;
        outline-offset: max(2em, 0.15em);
      }
    }

    input[type="radio"]::before {
      width: 13px;
      height:13px;
      border-radius: 50%;
      transition: 120ms transform ease-in-out;
      margin: 3px;
    }

    input[type="radio"]:checked::before {
      //transform: scale(0.7);
    }

  }
}


/* Adjustments for mobile phones for screens from 320px to 480px wide:*/
@media (max-width: 768px) {
  .wp-block-columns {
    margin: 0 !important;
  }
  .container.measures.group {
    span:first-child {
      display: block !important;
      margin-bottom: 5px !important;
    }
    .inputs.lists{
      margin: 0 15px 0 0 !important;
      input {
        margin: 0 5px 0 0 !important;
      }
    }
  }

}
