
.carousel {

  width: 100%;

  .carousel__slide{
    border: 1px solid transparent;
  }

  .wp-block-cover {
    padding: 0px;

  }

  .wp-block-group {
    padding: 1rem 2rem;
  }

  .wp-block-columns {
    .wp-block-column {
      padding: 0px;
      margin: 0px;
    }

  }
  padding: 0px;
  ul{
    margin: 0;
  }
  li {
    padding: 0px;
    min-height: 565px;

    .ui.fluid.container.entry-content {

    }

    .wp-block-buttons {
      padding: 10px 0px;

      .wp-block-button {
        margin: 0px;
      }
    }
  }


  .carousel__dot--selected {
  }
}

.carousel__dot-group {
  margin: 0px auto auto;
  text-align: center;


  .carousel__dot {
    pointer-events: all !important;
    background-color: $separator-grey;
    margin: 5px;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    border: 0px !important;

  }

  .carousel__dot:hover {
    background-color: $ke-blue;
  }

  .carousel__dot:focus {
    outline: none;
    box-shadow: none;
  }

  .carousel__dot:active {
    background-color: $ke-purple;
    box-shadow: none;
    transform: translateY(0px);
  }

  .carousel__dot--selected {
    background-color: $ke-blue;
  }

}
