
.ui.search {
  width: 200px;
  //margin-right: 10px;

  .results {
    &.header {
      font-family: Roboto;
      color: #66676d !important;
      font-size: 10px;
      padding: 7px 7px;
      color: #616161 !important;
      margin: 0px 16px;
      border-left: none;
      border-right: none;
      font-weight: 300;
    }

    h4 {
      color: #454545;
      margin-bottom: 10px !important;
    }
  }

  .ui.search input.prompt {
    border-radius: 3px;
  }

  .ui.icon.input > input {
    border-radius: 3px;
  }

  .ui.blue.segment:not(.inverted) {
    border-top: none !important;
  }

  .ui.search > .results {
    width: 315px !important;
    margin-top: 0px !important;
    border-radius: 0px !important;
    border: 1px solid #d4d4d5;
    border-top: none;
  }

  .ui.segment {
    box-shadow: none;
  }

  .has-standard-12-font-size, .has-standard-12-font-size {
    color: #9e9e9e;
  }

  .has-standard-12-font-size:hover, .has-standard-12-font-size:hover {
    color: #3b5b74
  }

  .has-standard-12-font-size h5 {
    color: #003a5b;
    font-size: 14px !important;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 3px !important;
    line-height: 16px;
    font-weight: 300;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .has-standard-12-font-size:hover h5 {
    color: #3b5b74;
  }

  .see-more-results {
    font-size: 12px;
    margin: 0px 23px;
    font-weight: 700;
    color: #4a5da8;
  }

  .ui.search > .results .result {
    padding: 4px 8px 0px 19px;
  }

  b {
    color: #003a5b;
    font-weight: 700;
  }

  .search-content {
    color: #666666;
  }

  .results.transition.visible{
    transform: translateX(-189px);
    margin-top: 0;
  }
}

