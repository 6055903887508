.container.header {
  height: 150px;
  padding: 0;
  margin: 0;
  box-shadow: none;
  left: auto;

  .background {
    border-bottom: 1px solid $light-gray;
    background-color: #f9f9f9;

  }

  .menu.branding {
    background-color: #f9f9f9;
    height: 96px;
    position: relative;
    z-index: 99999999;
  }

  .branding {
    &.menu {
      img {
        margin-top: $base-unit - 3;
      }
    }
  }

  .menu, .branding {
    padding: 0;
    margin: 0;
    max-width: 1366px;
    margin: auto;


    .divider {
      div {
        border-left: 1px solid $light-gray;
        height: 46px;
      }
    }

    .flag{
      &:before{
        width: $base-unit * 2;
        height: $base-unit * 2;
        margin-top: -5px;
        background-repeat: no-repeat !important;
        background: url("/flags/ke-flag-header.svg");
        background-size: 100% 100%;
        border: 1px solid lighten($light-gray, 5%);
      }
    }

    .item.fitted {
      color: #000;
      font-size: 22px;

    }

    .item {
      font-size: 15px;
      margin: 0px 1%;
    }

    .pages {
      align-self: flex-end;
      height: 96px;
      width: 100%;

      .item:nth-child(1) {
        margin-left: 4%;
      }

      .item {
        ///transform: translateY(-1px);
        a {
          cursor: pointer;
          color: $menu-link-color;
          &:hover {
            color: $ke-purple;
          }
        }

        box-sizing: border-box;
        margin: 0px;
        text-transform: uppercase;
        justify-content: center;
        height: 71px;
        min-width: 12%;
        padding: 0px 3%;

        align-self: flex-end;
        border: 1px solid transparent;
        padding-bottom: 25px;
      }

      .item.selected {
        font-weight: bold;
        height: 71px;
        color: $et-green;
        background-color: #FFF;
        border-bottom: 1px solid #FFF !important;

        a {
          color: $ke-purple;
        }
      }

      .item.has-child-items {
        color: $menu-link-color;
        padding-bottom: 25px;

        span::after {
          transition: 0.2s ease-in-out;
          transform: rotate(90deg);
          content: '\203A';
          margin-left: 13px;
          float: right;
          display: block;
        }

        span {
          cursor: pointer !important;
        }
      }

      .item.selected.has-child-items {
        z-index: 1;
        color: $ke-purple;
        border: 1px solid $light-gray;
        margin-bottom: -1px;
        padding-bottom: 28px;

        span::after {
          transform: rotate(-90deg);
          content: '\203A';
          margin-left: 13px;
          float: right;
          display: block;
        }

      }


    }
  }

  .container.child {
    transform: translateY(-1px);
    position: relative;
    z-index: 3;
    .menu {
      background-color: #ffffff;
      max-width: 100%;
      margin-top: 0px;
      padding: 0px;
      border-bottom: 1px solid $light-gray;
      border-top: 1px solid $light-gray;
      justify-content: center;
      position: absolute;
      z-index: 2;

      .item {
        margin-top: 1px;
        &:hover{
          a{
            color: $ke-purple;
          }
        }

        a {
          color: #9798a0;
        }

        text-transform: uppercase;
        padding: 0px 10px;
        margin: 0px .5%;

        .mark {
          width: 15px;
          height: 17px;
          background-size: 15px 15px;
          margin-right: 11px;
          background-image: url("/child-menu-arrow.svg");
          background-repeat: no-repeat;
        }
      }

      .item.selected {

        .mark {
          width: 15px;
          height: 16px;
          background-size: 15px 15px;
          margin-right: 10px;
          background-image: url("/old/header_arrow_green.png");
          background-repeat: no-repeat;
        }
      }

      .item.selected {
        color: $et-green;
      }

      .item.active {
        .mark {
          @extend .mark;
          background-image: url("/child-menu-arrow-active-ke.svg");
        }

        a {
          font-weight: 800;
          color: $ke-purple;

        }
      }
    }
  }
}

.url.breadcrumbs {
  margin-top: $base-unit * 3;
  font-size: 16px;
  position: relative;
  z-index: 2;

  span {
    color: $breadcrumb-grey;
  }

  span {
    color: $breadcrumb-grey;
  }

  span:after, a:not(:last-child):after {
    color: $breadcrumb-grey;
    font-family: "Roboto";
    content: '/';
    display: inline;
    font-size: 16px;
    margin: 0px 10px;
  }

  a {
    color: $breadcrumb-grey;
  }


  * {
    padding: 0px;
    margin: 0px;
  }

  .active {
    color: $ke-purple;
  }

  .entry-content {
    display: inline !important;;
  }

  .title {
    font-size: 16px;


  }

  height: 30px;
}


.ui.search {
  input.prompt {
    border-radius: 0px;
  }

  i.search.icon {
    color: lightgrey;
    font-size: 0.8rem;
    opacity: 1;
  }
}


.result{
  *{
    padding: 0px !important;
    margin: 0px !important;;
  }
  .search-content{
    font-size: 16px !important;
  }
}


.hamburger-menu {
  display: none;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 15px;
  right: 16px;
  z-index: 999999999;
  transition: none; /* Ensures no transition or animation initially */
}

.hamburger-menu div {
  width: 22px;
  height: 2.3px;
  background-color: #54657E;
  margin-bottom: 4px;
  border-radius: 10px;
  transform-origin: 50% 50%;
  transition: none; /* Initially no transition for child divs either */
}

/* Apply animations only when the animate class is added */
.hamburger-menu.animate.open div:first-child {
  animation: moveAndRotate1 0.3s ease-in-out forwards;
}
.hamburger-menu.animate.open div:last-child {
  animation: moveAndRotate2 0.3s ease-in-out forwards;
}

.hamburger-menu.animate.close div:first-child {
  animation: reverseMoveAndRotate1 0.3s ease-in-out forwards;
}
.hamburger-menu.animate.close div:last-child {
  animation: reverseMoveAndRotate2 0.3s ease-in-out forwards;
}

@keyframes moveAndRotate1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(6px) rotate(45deg);
  }
}
@keyframes moveAndRotate2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(-6px) rotate(-45deg);
  }
}

@keyframes reverseMoveAndRotate1 {
  0% {
    transform: translateY(6px) rotate(45deg);
  }
  50% {
    transform: translateY(6px) rotate(0deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

@keyframes reverseMoveAndRotate2 {
  0% {
    transform: translateY(-6px) rotate(-45deg);
  }
  50% {
    transform: translateY(-6px) rotate(0deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

.hamburger-menu.animate.open {
  .middle-line {
    opacity: 0;
    transition: opacity 0s ease 0.15s; /* Apply the opacity change over 0.5 seconds, after a 1 second delay */
  }
}

.hamburger-menu.animate.close {
  .middle-line {
    opacity: 1;
    transition: opacity 0s ease 0.15s; /* Apply the opacity change over 0.5 seconds, after a 1 second delay */
  }
}


/* Adjustments for mobile for screens from 320px to 480px wide:*/

@media (max-width: 480px) {

  .container.header {
    height: 100px !important;
  }

  .hamburger-menu {
    display: block;
    right: 16px !important;
  }

  .child {
    display: none !important;
  }
  .menu.branding {
    height: 64px !important;

    .item {
      margin: 0 !important;
    }

    .brand.logo.small {
      height: 60px;
      margin: 4px 4px 0 16px !important;
    }
  }
  .divider {
    div {
      height: 32px !important;
    }
  }
  .flag {
    margin-left: 15px;
  }
  .site.name {
    display: none;
  }
  a.fitted.item {
    flex-grow: 9999 !important;
  }
  .menu.branding {
    .pages {
      z-index: 999999 !important;
      visibility: hidden;
      opacity: 0;
      flex-direction: column;
      transform: translateX(-100%); /* Start off-screen to the left */
      position: fixed;
      top: 0;
      width: 320px !important;
      height: 100vh !important;
      margin: 0 !important;
      background-color: #F9F9F9 !important;
      border-radius: 0 !important;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-out, opacity 0.3s ease-out 0.3s, visibility 0.3s ease-out;

      .item {
        //flex: 1;
        opacity: 0;
        margin: 0 !important;
        padding-left: 26px !important;
        padding-bottom: 0 !important;
        height: 60px !important;
        width: 320px;
        justify-content: flex-start !important;
        top: 48px;
        background-color: transparent !important;
        border: none !important;

        a, span {
          font-size: 21px;
          color: #54657E;
        }

        .mark {
          width: 15px;
          height: 17px;
          background-size: 15px 15px;
          margin-right: 11px;
          background-image: url("/child-menu-arrow.svg");
          background-repeat: no-repeat;
        }
      }

      .child-item {
        height: 50px !important;
        transform: translateY(-5px);

        a {
          font-size: 18px !important;
        }
      }
    }

    .pages.show {
      visibility: visible;
      opacity: 1;
      transform: translateX(0); /* Move into view */
      transition: transform 0.3s ease-out, opacity 0s;
      z-index: 9999;
      overflow-y: auto;
      height: 100%;

      .item {
        opacity: 1;
      }
    }
  }

  .container.header .menu .pages .item.selected, #root .container.header .branding .pages .item.selected {
    border-bottom: none !important;
  }

  .child-item.active {
    a {
      color: $ke-purple !important;
      font-weight: 600 !important;
    }
  }

  .item.has-child-items {

    span:hover {
      color: $ke-purple;
    }
  }
  .has-child-items.selected {
    span {
      color: $ke-purple;
    }
  }
  .ui.float-search {
    margin: 12px 55px 0 0px;
    height: 54px !important;
    transform: translateY(-6.5px);
    i {
      margin-top: 5px;
    }
  }

  #float-input-container {
    top: 62px !important;
    padding-left: 16px !important;
    .float-results-header {
      padding-left: 16px !important;
      margin-left: 0 !important;
    }
    .searching-results {
      padding-left: 16px !important;
    }
  }

  .wp-block-cover.header-cover {
    margin-top: -86px;
  }

}



/* Adjustments for tablets for screens from 481 to 768px wide:*/

@media (min-width: 481px) and (max-width: 768px) {

  .container.header {
    height: 100px !important;
  }

  .hamburger-menu {
    display: block;
    right: 40px !important;
  }

  .child {
    display: none !important;
  }
  .menu.branding {
    height: 64px !important;

    .item {
      margin: 0 !important;
    }

    .brand.logo.small {
      height: 60px;
      margin: 4px 4px 0 40px !important;
    }
  }
  .divider {
    div {
      height: 32px !important;
    }
  }
  .flag {
    margin-left: 15px;
  }
  .site.name {
    font-size: 19px;
  }
  a.fitted.item {
    flex-grow: 9999;
  }
  .menu.branding {
    .pages {
      z-index: 999999 !important;
      visibility: hidden;
      opacity: 0;
      flex-direction: column;
      transform: translateX(-100%); /* Start off-screen to the left */
      position: fixed;
      top: 0;
      width: 320px !important;
      height: 100vh !important;
      margin: 0 !important;
      background-color: #F9F9F9 !important;
      border-radius: 0 !important;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-out, opacity 0.3s ease-out 0.3s, visibility 0.3s ease-out;

      .item {
        //flex: 1;
        opacity: 0;
        margin: 0 !important;
        padding-left: 26px !important;
        padding-bottom: 0 !important;
        width: 320px;
        height: 60px !important;
        justify-content: flex-start !important;
        top: 48px;
        background-color: transparent !important;
        border: none !important;

        a, span {
          font-size: 21px;
          color: #54657E;
        }

        .mark {
          width: 15px;
          height: 17px;
          background-size: 15px 15px;
          margin-right: 11px;
          background-image: url("/child-menu-arrow.svg");
          background-repeat: no-repeat;
        }
      }

      .child-item {
        height: 50px !important;
        transform: translateY(-5px);

        a {
          font-size: 18px !important;
        }
      }
    }

    .pages.show {
      visibility: visible;
      opacity: 1;
      transform: translateX(0); /* Move into view */
      transition: transform 0.3s ease-out, opacity 0s;
      z-index: 9999;
      overflow-y: auto;
      height: 100%;

      .item {
        opacity: 1;
      }
    }
  }

  .container.header .menu .pages .item.selected, #root .container.header .branding .pages .item.selected {
    border-bottom: none !important;
  }

  .child-item.active {
    a {
      color: $ke-purple !important;
      font-weight: 600 !important;
    }
  }

  .item.has-child-items {

    span:hover {
      color: $ke-purple;
    }
  }
  .has-child-items.selected {
    span {
      color: $ke-purple;
    }
  }
  .ui.float-search {
    margin: 12px 100px 0 20px;
    height: 54px !important;
    transform: translateY(-6.5px);
    i {
      margin-top: 5px;
    }
  }

  #float-input-container {
    top: 62px !important;
    padding-left: 40px !important;
    .float-results-header {
      padding-left: 40px !important;
      margin-left: 0 !important;
    }
    .searching-results {
      padding-left: 40px !important;
    }
  }
  .wp-block-cover.header-cover {
    margin-top: -86px;
  }

}


/* Adjustments for laptops for screens from 769 to 1024px wide:*/

@media (min-width: 769px) and (max-width: 1024px) {

  .container.header {
    height: 120px !important;
  }

  .hamburger-menu {
    display: block;
    right: 105px !important;
  }

  .child {
    display: none !important;
  }
  .menu.branding {
    height: 64px !important;
    .item {
      margin: 0 !important;
    }
    .brand.logo.small {
      height: 60px;
      margin: 4px 4px 0 105px !important;
    }
  }
  .divider {
    div {
      height: 32px !important;
    }
  }
  .flag {
    margin-left: 15px;
  }
  .site.name {
    font-size: 19px;
  }
  a.fitted.item {
    flex-grow: 9999;
  }
  .menu.branding {
    .pages {
      z-index: 999999!important;
      visibility: hidden;
      opacity: 0;
      flex-direction: column;
      transform: translateX(-100%); /* Start off-screen to the left */
      position: fixed;
      top: 0;
      width: 320px !important;
      height: 100vh !important;
      margin: 0 !important;
      background-color: #F9F9F9 !important;
      border-radius: 0 !important;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-out, opacity 0.3s ease-out 0.3s, visibility 0.3s ease-out;
      .item {
        //flex: 1;
        opacity: 0;
        margin: 0 !important;
        padding-left: 26px !important;
        padding-bottom: 0 !important;
        width: 320px;
        height: 60px !important;
        justify-content: flex-start !important;
        top: 48px;
        background-color: transparent !important;
        border: none !important;
        a, span {
          font-size: 21px;
          color: #54657E;
        }
        .mark {
          width: 15px;
          height: 17px;
          background-size: 15px 15px;
          margin-right: 11px;
          background-image: url("/child-menu-arrow.svg");
          background-repeat: no-repeat;
        }
      }
      .child-item {
        height: 50px !important;
        transform: translateY(-5px);
        a {
          font-size: 18px !important;
        }
      }
    }

    .pages.show {
      visibility: visible;
      opacity: 1;
      transform: translateX(0); /* Move into view */
      transition: transform 0.3s ease-out, opacity 0s;
      z-index: 9999;
      overflow-y: auto;
      height: 100%;

      .item {
        opacity: 1;
      }
    }
  }

  .container.header .menu .pages .item.selected, #root .container.header .branding .pages .item.selected {
    border-bottom: none !important;
  }

  .child-item.active {
    a {
      color: $ke-purple !important;
      font-weight: 600 !important;
    }
  }

  .item.has-child-items {

    span:hover {
      color: $ke-purple;
    }
  }
  .has-child-items.selected {
    span {
      color: $ke-purple;
    }
  }
  .ui.float-search {
    margin: 12px 175px 0 20px;
    height: 54px !important;
    transform: translateY(-6.5px);
    i {
      margin-top: 5px;
    }
  }
  #float-input-container {
    top: 62px !important;
  }
  .wp-block-cover.header-cover {
    margin-top: -86px;
  }

}


/* Adjustments for desktop for screens from 1025 to 1365px wide:*/

@media (min-width: 1025px) and (max-width: 1365px) {

  .container.header {
    height: 130px !important;
  }

  .container.header {
    .menu.branding {
      height: 64px;
      .item {
        margin: 0;
      }
      .brand.logo.small {
        height: 60px;
        margin: 4px 4px 0 48px;
      }
    }
    .divider {
      div {
        height: 32px !important;
      }
    }
    .site.name {
      font-size: 19px;
    }
    .flag {
      margin-left: 15px;
    }
    .pages {
      height: 54px !important;
      margin: 0;
      .item {
        height: 54px !important;
        span, a{
          margin-top: 18px;
          font-size: 14px;
        }
      }
    }
  }
  .ui.float-search {
    margin: 0 48px 0 48px;
    height: 54px !important;
    transform: translateY(-6.5px);
    i {
      margin-top: 5px;
    }
  }
  #float-input-container {
    top: 62px !important;
    padding-left: 60px !important;
    .float-results-header {
      padding-left: 60px !important;
      margin-left: 0 !important;
    }
    .searching-results {
      padding-left: 60px !important;
    }
  }
  .wp-block-cover.header-cover {
    margin-top: -86px;
  }
}
