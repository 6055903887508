.back-to-top-container{
  border-radius: 5px;
  padding: 10px;
  position: static;
  background-color: #ecb040;
  .icon-wrapper {
    height: 45%;
    font-size: 10px;
    text-align: center;
    position: static;
    background-color: #ecb040;
    .custom-icon {
      font-size: 25px;
      bottom: 5px;
      position: static;
      text-align: center;
      color: #fff;
      background-color: #ecb040;
    }
  }
  .btn-text{
    height: 45%;
    font-size: 14px;
    color: #fff;
    text-align: center;
    font-weight: bold;
  }
}

.top-navigator{
  right: 0px !important;
  margin-bottom: 0px;

  div.ui.menu{

    a.item{
      color: white;
    }

  }

  .ui.menu .item:before{
    width: 0px;
  }

}

.top-navigator{
  background-color: $ke-blue;

  div.ui.menu{

    a.item{
      color: white;
    }

  }

  .ui.menu .item:before{
    width: 0px;
  }

}