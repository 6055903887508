.time.line {
  foreignObject {
    > div {
      //font-weight: 900;
    }
  }

  .tooltip {
    a {
      pointer-events: all;
    }

    height: auto;
    min-width: 450px;
	  max-width: 625px;
	  width: auto;
    z-index: 1000;

    .tooltip {
      @include border-radius(15px);
      padding: 15px;
      width: $extend;
    }

    .wp-block-columns {
      margin-bottom: 0px;
    }

    p {
      color: inherit;
      pointer-events: none;
    }
    a {
      color: inherit;
      font-weight: bold;
    }

    img {
      @include border-radius(15px);
    }

  }

  .pointer {
    position: absolute;

    top: 50%;
    width: 0;
    height: 0;

  }

  text {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 22px;

  }

  text, circle {
    //cursor: pointer;
    pointer-events: all;
  }
}

.tcdi.new-time-line {
  padding: 30px;
  background-color: rgba(0, 0, 0, .8);

  .carousel-section {
    padding: 20px;
      background-color: #FFF;
      border: 1px solid red;

  }

  
  $color: #65ff78;
  div.active {
    background-color: #35ffc3;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    animation: ripple 0.7s linear infinite;
  }

  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 rgba($color, 0.3),
      0 0 0 1em rgba($color, 0.3),
      0 0 0 3em rgba($color, 0.3),
      0 0 0 5em rgba($color, 0.3);
    }
    100% {
      box-shadow: 0 0 0 1em rgba($color, 0.3),
      0 0 0 3em rgba($color, 0.3),
      0 0 0 5em rgba($color, 0.3),
      0 0 0 8em rgba($color, 0);
    }
  }

}
