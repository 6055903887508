.language.selector {

  .ui.image {
    margin-top: 0px !important;
  }

  .selected.item {
    font-weight: bold !important;
  }
}

.inline.language.selector {

  a {
    font-size: 13px;
    text-transform: uppercase;
  }

  .ui.image {
    display: inline-block;
    margin-right: 5px;

  }

  .selected {
    font-weight: bold !important;
  }
}

.single.language.selector {

  margin-bottom: 4px;
  text-transform: uppercase;

  a:after {
    margin-left: 3px;
    margin-right: 3px;
    content: '|';

  }

  a {
    font-size: 13px;
    cursor: pointer;
    color: #3a628a;
  }


  a:last-child:after {
    display: none;
  }


  .selected {
    font-weight: bold !important;
  }

}